@import "~antd/dist/antd.dark.css";
#components-layout .logo {
  height: 60px;
  /* background: rgba(255, 255, 255, 0.2); */
}

.ant-layout {
  background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(30, 84, 150, 0.3) 0%,
      rgba(196, 196, 196, 0) 100%
    )
    #000000;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
}

/* .components-layout,
  .site-layout-sub-header-background,
  .ant-layout-footer,
  .ant-layout-sider-dark,
  .ant-menu,
  .ant-menu-item {
    background-color: transparent;
  } */

a {
  text-decoration: none;
}

.logo {
  /* background: rgba(255, 255, 255, 1); */
  text-align: center;
}

.logo h1 {
  /* color: white; */
  padding: 0;
  margin: 0;
  font-size: 20px;
}

#components-layout {
  min-height: 100vh;
}

.title {
  font-size: 20px;
  margin-bottom: 18px;
}

/* .ant-form-item-control-input,
  .ant-input-affix-wrapper,
  .ant-input,
  .ant-select-selector,
  .ant-picker {
    border-color: white!important;
  } */

.list-group-item {
  border: 0;
}

footer {
  font-size: 14px;
}

.navbar-nav .nav-item {
  margin: 0 20px;
  text-align: center;
}

.fs-sm {
  font-size: 14px;
}

.ant-popover-inner-content {
  padding: 0;
}

.list-group-item-dark {
  color: white;
  background-color: #333;
}

.list-group,
.list-group-item {
  background-color: transparent !important;
  color: inherit;
}

.required > .ant-form-item-label > label::before {
  display: inline-block;
  margin-right: 4px;
  color: #a61d24;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
